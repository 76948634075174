import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";


const BannerWithAfterContent = ({ module, location, pageTitle, parentId, author }) => {
  const [curentHref, setCurentHref] = useState("");

  useEffect(() => {
    setCurentHref(window.location.href)
  }, []);

  return (
    <>
      <section className="blog-content-single">
        <div className="blog-content-banner lg:pt-70 pt-60">
          <div className={`banner lg:mx-70 mx-30 h-[662px] lgscreen:h-[70vh] mdscreen:h-[70vh] relative${module?.extraClass ? ' ' + module?.extraClass : ""}`}>
            <div className='banner-img h-full flex justify-start'>
              {
                module?.backgroundImage && (
                  <GatsbyImage
                    image={getImage(module?.backgroundImage)}
                    alt={module.backgroundImage.altText}
                    className="img"
                  />
                )
              }
              {
                module?.elephantImage && (
                  <div className='banner-second-img absolute bottom-0 left-0'>
                    <GatsbyImage
                      image={getImage(module?.elephantImage)}
                      alt={module?.elephantImage?.altText}
                      className='w-[400px] object-contain mdscreen:w-full'
                    />
                  </div>
                )
              }
              <div className="w-full relative justify-between flex mdscreen:flex-col mdscreen:justify-start">
                <div className='banner-info absolute bottom-[60px] lgscreen:bottom-40 mdscreen:bottom-[120px] px-15 text-left z-9 lg:pl-80 max-w-[950px] pb-50'>
                  {module?.preHeading && <span className='uppercase text-white text-15 mb-10 inline-block w-full'>{module.preHeading}</span>}
                  {module?.heading && <h1 className='lg:text-55 lg:leading-60 xlscreen:text-[45px] xlscreen:leading-45 lgscreen:text-35 mdscreen:text-26 mdscreen:leading-27 lgscreen:mb-5 text-white uppercase '>{module.heading}</h1>}
                  {
                    module.displayWrittenBy && (
                      <span className='uppercase text-white text-16 mb-10 inline-block w-full '>Written By <span className="italic text-white font-secondary capitalize text-22 pl-5 ">{author}</span></span>
                    )
                  }
                  <div className="tracking-[0.025em]">
                    {module?.description && parse(module.description)}
                  </div>


                  {
                    module?.bestFor && (
                      <div className='flex flex-wrap mt-5 justify-between '>
                        <div className='banner-list flex flex-wrap items-center'>
                          <span>{module?.bestFor?.heading}</span>
                          <ul className='flex flex-wrap space-x-5'>
                            {
                              module?.bestFor?.button?.map((item, index) => (
                                <li key={index.toString()}>
                                  {item?.value}
                                </li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                    )
                  }

                </div>

                {
                  (module?.tripHandler || module?.location) && (
                    <div className='banner-btm-right absolute bottom-0 right-0 text-right lg:pr-70 pb-100 lgscreen:pr-15 z-9 pl-50 mdscreen:pl-15 xlscreen:pb-[60px] lgscreen:pb-[300px] mdscreen:hidden'>
                      {module?.tripHandler && <h6 className="pb-[6px]">Originally curated for &nbsp; <span>{parse(module.tripHandler)}</span> </h6>}
                      {module?.location && <span>{parse(module?.location)}</span>}
                    </div>
                  )
                }

              </div>
              <div className="flex flex-col items-end justify-end absolute bottom-0 lg:right-0 lgscreen:w-full lg:text-right lg:pr-70 px-15 pb-120 lgscreen:pb-40 mdscreen:pb-60 z-9">
                {module?.imageCredit && (
                  <p className="mt-5 font-body font-400 text-12 leading-[14px] text-right text-white">Image supplied by {module?.imageCredit}
                  </p>)}
              </div>
            </div>
          </div>
        </div>
        <div className="blog-common-content flex justify-center lg:py-50 py-30 lg:px-70 px-30">
          <p>{parse(module.bannerContentAfterImage)}</p>
        </div>
      </section>
    </>
  )
}

export default BannerWithAfterContent;
export const BannerWithAfterContentFragment = graphql`
  fragment BannerWithAfterContentFragment on WpPost_Postcontent_PostContent_BannerWithAfterContent {
    backgroundImage {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1920)
    }

    elephantImage {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 400)
    }
    id
    title
    imageCredit
    showSocialMediaIcons
    shareTo
    preHeading
    inToImageTag
    hideSection
    heading
    fieldGroupName
    extraClass
    displayWrittenBy
    bannerContentAfterImage
  }
`;



