import { graphql } from "gatsby";
import React from "react";

const SocialMediaShare = ({ module, postLink }) => {
  if (module?.shareTo?.length === 0) {
    return null;
  }
  return (
    <section className={`blog-icon lg:pt-60 pt-30${module?.extraClass ? " " + module.extraClass : ""}`}>
      <div className="flex justify-center items-center fade-ani">
        <span className="text-red-200 uppercase text-19 mr-15 ">{module?.title}</span>
        <ul className="flex gap-x-2 ">
          {
            module?.shareTo?.indexOf("telegram") > -1 && (
              <li>
                <a href={`https://telegram.me/share/url?url=${postLink}`} target="_blank">
                  <img
                    src="/images/share-red.png"
                    loading="lazy"
                    alt="icon-share"
                  />
                </a>
              </li>
            )
          }
          {
            module?.shareTo?.indexOf("facebook") > -1 && (
              <li>
                <a href={`https://www.facebook.com/sharer.php?u=${postLink}`} target="_blank">
                  <img
                    src="/images/facebook-red.png"
                    loading="lazy"
                    alt="icon-facebook"
                  />
                </a>
              </li>
            )
          }
          {
            module?.shareTo?.indexOf("twitter") > -1 && (
              <li>
                <a href={`https://twitter.com/share?url=${postLink}`} target="_blank">
                  <img
                    src="/images/twitter-red.png"
                    loading="lazy"
                    alt="icon-twitter"
                  />
                </a>
              </li>
            )
          }
        </ul>
      </div>
    </section>
  )
}

export default SocialMediaShare;
export const PostSocialMediaShareFragment = graphql`
  fragment PostSocialMediaShareFragment on WpPost_Postcontent_PostContent_SocialMediaShare {
    id
    extraClass
    hideSection
    title
    shareTo
  }
`;