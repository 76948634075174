import { graphql } from "gatsby";
import React from "react";
import Banner from "../components/Banner"
import BlogContent from "../components/BlogContent"
import Quote from "../components/Quote"
import SocialMediaShare from "../components/SocialMediaShare"
import RelatedBlog from "../components/RelatedBlog"
import BannerWithAfterContent from "../components/BannerWithAfterContent"

const Post = ({ modules, location,pageContext, postLink, author }) => {
  const components = {
    Banner,
    BlogContent,
    Quote,
    RelatedBlog,
    SocialMediaShare,
    BannerWithAfterContent
  }

  return (
    <>
      {modules
        ? modules.filter((module) => !module?.hideSection || module?.hideSection === "no").map((module, i) => {
            const moduleName = module.__typename.replace(
              "WpPost_Postcontent_PostContent_",
              ""
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                module,
                pageContext,
                location,
                postLink,
                author
              })
            );
          })
        : null}
    </>
  );
}

export default Post;
export const PostModulesFragment = graphql`
  fragment PostModulesFragment on WpPost {
    postContent {
      postContent {
        __typename
        ...BlogContentFragment
        ...PostQuoteFragment
        ...PostSocialMediaShareFragment
        ...PostRelatedBlogFragment
        ...BannerWithAfterContentFragment
      }
    }
  }
`;