import { graphql } from "gatsby";
import React from "react";

const Quote = ({ module }) => {
  return (
    <section className={`blog-quote lg:pt-80 pt-40 ${module?.extraClass ? " " + module.extraClass : ""}`}>
      <div className="container mx-auto px-30 lgscreen:px-15 text-center fade-ani">
        <h3>{module?.quoteText}</h3>
        <span className="mt-30 inline-block text-14 uppercase text-black-500 ">{module?.quoteBy}</span>
      </div>
    </section>
  )
}

export default Quote;
export const PostQuoteFragment = graphql`
  fragment PostQuoteFragment on WpPost_Postcontent_PostContent_Quote {
    id
    extraClass
    hideSection
    quoteText
    quoteBy
  }
`;