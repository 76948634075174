import { graphql } from "gatsby";
import React, { useEffect } from "react";
import PageLayout from "../components/Layout/Page";
import Seo from "../components/Seo";
import Post from "../modules/Post";

const PostTemplate = (props) => {
  const post = props.data.post;
  const modules = post.postContent?.postContent || [];
  const postLink = post?.link || "";

  useEffect(() => {
    document.body.classList.add("blog-single-custom");
    document.body.classList.add("header-sticky");
    document.body.classList.remove("nav-menu-open");
    document.body.classList.remove("video-modal-open");
  }, []);

  return (
    <PageLayout>
      <Seo seo={props.data.post.seo} />
      <Post modules={modules} pageContext={props.pageContext} location={props.location} postLink={postLink} author={post.author.node.name} />
    </PageLayout>
  )
}

export default PostTemplate;
export const PostById = graphql`
  query postById ($id: String) {
    post: wpPost(id: { eq: $id }) {
      id
      uri
      link
      author {
        node {
          name
        }
      }
      ...seoPostFragment
      ...PostModulesFragment
    }
  }
`;